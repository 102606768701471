import React from 'react';
import Layout from "../components/layout";
import Helmet from "../components/helmet";
import PageContent from '../page_content/maltherapie-mit-kindern.mdx';

let config = {
  pageTitle: 'Maltherapie mit Kindern'
}


export default ({ children, ...props }) => {
    return (
      <Layout>
        <Helmet title={config.pageTitle}/>
        <PageContent />
        {children}
      </Layout>
    )
}