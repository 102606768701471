import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ImgKinderBeimMalen from './images/kinder_beim_malen';
import ImgSchiffgrafik from './images/schiffgrafik';
import ImgStufenbild from './images/stufenbild';
import Image from './../components/image';
import Caption from './../components/caption';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Maltherapie mit Kindern`}</h1>
    <div className="columns page-section">
    <div className="column is-three-fifths">
        <h2>Ausdruckszentrierte Prozesse begleiten</h2>
        <p>
            Die Arbeit mit Kindern (in einem Kinderdorf), die Entwicklungsstörungen, ADHS, Minderbegabung oder autistische Störungen aufweisen, haben mir gezeigt, dass eine  Verarbeitung von konfliktreichen Ereignissen durch Malen, Handwerken oder Gestalten mit unterschiedlichsten Materialien in Verbindung mit anderen Therapien möglich ist. Die Reflexion über das Geschehene ist selten möglich. Umso mehr kommt dem ausdruckszentrierten Prozess eine sehr große Bedeutung zu. Die Kinder müssen abgeholt werden, wo sie emotional gerade stehen. Das spielerische Element ist unabdingbarer Bestandteil der Therapie. 
        </p>
    </div>
    <div className="column image-column">
        <ImgStufenbild mdxType="ImgStufenbild" />
    </div>
    </div>
    <div className="columns page-section">
    <div className="column image-column">
        <ImgKinderBeimMalen mdxType="ImgKinderBeimMalen" />
    </div>
    <div className="column is-three-fifths">
        <h2>Meine Grundhaltung im therapeutischen Setting</h2>
        <p>
            Kinder sollen im therapeutischen Setting angstfrei in einen eigenen Ausdruck kommen können. Die Besonderheit des Kindes soll zum Ausdruck kommen, seine Einzigartigkeit. Dazu gehört ganz wesentlich das Annehmen von sich selbst mit den jeweils damit verbundenen Möglichkeiten, aber auch Schwierigkeiten.
        </p>
        <p>
            Das Ausleben von „Neu-Lust“ führt zum Erlebnis des eigenen Ausdrucks von Begabungen und Kompetenzen wodurch sich das Kind als SELBSTWIRKSAM erleben kann. Auf diese Weise entwickeln die Kinder sehr oft eine BEGEISTERUNG in ihrem Schaffen und „Begeisterung ist der beste Dünger für das Gehirn“ (vgl. HÜTHER 2014).
        </p>
    </div>
    </div>
    <div className="columns page-section">
    <div className="column schneemutter-column">
        <figure className="is-pulled-right schneemutter-pulled">
            <Image filename="schneemannmutter.jpg" className="schneemutter  image-column" imageStyle={{
            objectFit: "contain"
          }} mdxType="Image" />
            <Caption mdxType="Caption">
                Schneemutter
            </Caption>
        </figure>
        <p>
            Regelmäßig gehen im maltherapeutischen Setting die Symptome Aufmerksamkeitsdefizit, Impulsivität, Angespanntheit und motorische Unruhe zurück! Lassen wir das Kind angeleitet oder frei malen und gestalten, so ist der Gestaltungsvorgang an sich schon „ein kreativer Veränderungsprozess, in dem etwas Neues entsteht“ (Riedl, 1992, 26), der befreit von Druck und Last. Symbole treten zu Tage, wie z.B. die „Schneemutter“ aus Ton (s. Bild). Damit entsteht ein Gegenüber, mit dem das Kind in Kontakt oder in Auseinandersetzung treten kann. 
        </p>
        <p>
            Wesentlich ist auch, „dass die Beziehung zwischen Therapeut und Klient in der Maltherapie immer auf ein „Drittes“ das Bild, bezogen ist, dem sie sich gemeinsam gegenübersehen, das sie gemeinsam betrachten. Das Heilende ist hier nicht einfach die Beziehung, sondern die dieser Beziehung entsprungene Botschaft aus dem Unbewußten des Klienten, von diesem sichtbar gemacht und zum Bild gestaltet. Der Therapeut nimmt das Bild an und interessiert sich dafür und vermag schon allein dadurch das Vertrauensverhältnis (…) zum Klienten zu konstellieren“ (RIEDEL aaO, 47)
        </p>
    </div>
    </div>
    <div className="page-section">
    <div className="card link-card">
        <p className="has-text-centered">
            <strong>ADHS, die große Suche nach der verlorenen Mitte</strong><br />
            Wolfgang Engelhardt<br />
            <small> erschienen in der Fachzeitschrift "gestaltungsprozesse" im Heft 09/2016</small>
        </p>
        <div className="has-text-centered">
            <a href="/ADHS-Engelhardt-2016.pdf" className="link-button">zum Artikel →</a>
        </div>
    </div>
    </div>
    <div className="page-section has-text-centered">
    <div className="card link-card">
        <strong>Übersicht über die unverhandelbaren Quellen<br />kindhaften Wachstums</strong>
        <small>in Anlehnung an RENZ-POLSTER, Herbert; HÜTHER, Gerald 2013</small>
        <ImgSchiffgrafik mdxType="ImgSchiffgrafik" />
        <div className="has-text-centered" style={{
          "marginTop": "1rem"
        }}>
            <a href="/wie-kinder-heute-wachsen--Engelhardt-2015.jpg" className="link-button" target="_blank">Volle Größe anzeigen →</a>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      